<template>
  <el-dialog
    :title="info.name + '回放'"
    :visible.sync="livePlaybackVisible"
    width="90%"
    :before-close="handlelivePlaybackClose"
  >
    <el-container v-if="livePlaybackFile && livePlaybackFile.length > 0">
      <el-container>
        <el-aside width="350px" height="380px">
          <div class="live-playback-list">
            <el-menu class="el-menu-con" mode="vertical" @select="handleSelect">
              <el-menu-item
                v-for="i in livePlaybackFile"
                :key="i.id"
                :index="i.id"
                >{{ i.stratTime }}~{{ i.endTime }}</el-menu-item
              >
            </el-menu>
          </div>
        </el-aside>
        <el-main>
          <div v-if="active" >
            <div id="videoContain"></div>
          </div>
          <el-empty v-else description="请选择回放列表"></el-empty>
        </el-main>
      </el-container>
    </el-container>
    <el-empty :description="$t('text.noFile')" v-else></el-empty>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handlelivePlaybackClose">{{
        $t("text.close")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Dialog, Toast } from "vant";
import { mapActions, mapState } from "vuex";
import { getLiveBroadcastDetails } from "../../api/home.js";
import moment from "moment";
export default {
  data() {
    return {
      livePlaybackVisible: false,
      info: {},
      livePlaybackFile: [],
      active: "",
    };
  },
  methods: {
    moment,
    onShow(id) {
      getLiveBroadcastDetails({ LiveId: id }).then((res) => {
        this.info = res.data;
        this.livePlaybackFile = res.data.liveVideoRecordingDtos;
      });
      this.livePlaybackVisible = true;
    },
    handleSelect(key) {
      this.active = key;
      const foundObject = this.livePlaybackFile.find((obj) => obj.id === key);
      if (foundObject.signData) {
        let signData = {
          fileID: foundObject.signData.fileid,
          appID: foundObject.signData.appid,
          psign: foundObject.signData.pSign,
        };

        if (this.player) {
          this.player.loadVideoByID(signData);
          return;
        }
        this.$nextTick(() => {
          const video = document.createElement("video");
          video.setAttribute("id", "player-container-id");
          video.setAttribute("playsinline", true);
          video.setAttribute("webkit-playsinline", true);
          video.setAttribute("autoplay", true);
          video.setAttribute("style", "width:100%;object-fit:contain");
          video.className = "video";
          document.getElementById("videoContain").appendChild(video);
          this.player = TCPlayer("player-container-id", {
            fileID: signData.fileID,
            appID: signData.appID,
            psign: signData.psign,
          });
        });
      } else {
        if (this.player) {
          this.player.dispose();
          this.player = null;
        }
      }
    },
    // 回放列表
    handlelivePlaybackClose() {
      // 清除由modalshow方法添加的video元素
      // const videoContainer = document.getElementById("videoContain");
      // if (videoContainer) {
      //   // 移除所有class为'video'的子元素
      //   while (videoContainer.firstChild) {
      //     if (videoContainer.firstChild.classList.contains("video")) {
      //       videoContainer.removeChild(videoContainer.firstChild);
      //     }
      //   } // 同时，你可能还希望停止所有的TCPlayer实例
      //   // this.player.forEach((player) => {
      //   //   // 这里假设TCPlayer有一个方法来停止或销毁实例
      //   //   // 你需要查看TCPlayer的文档或API来确定正确的方法
      //   //   player.pause() || player.dispose(); // 伪代码，使用TCPlayer提供的正确方法
      //   // }); // 清除players数组（可选）
      // }
      const videoElement = document.getElementById("player-container-id");
      if (videoElement) {
        videoElement.remove();
      }
      if (this.player) {
        this.player.pause() || this.player.dispose();
        this.player = null;
      }

      this.livePlaybackVisible = false;
      this.info = {};
      this.livePlaybackFile = [];
      this.active = "";
    },
  },
};
</script>

<style lang="less" scoped>
ul,
li {
  list-style: none;
}
/deep/.el-dialog__body{
height: 400px;
}
.live-playback-list {
  overflow-y: scroll;
  scrollbar-width: none;
  font-size: 20px;
  /deep/.el-menu-item {
    font-size: 16px;
  }
}
/deep/ .tcplayer {
  height: 360px !important;
}
</style>
