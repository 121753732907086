<template>
  <div class="home">
    <div class="home-left">
      <div class="header">
        <div class="header-left">
          <p>
            <span>{{ $t("text.projectName") }}</span>
            <span>{{ $t("text.projectUnit") }}</span>
          </p>
          <p>
            {{ $t("text.projectintroduce") }}
          </p>
          <div>
            <img src="@/assets/notice.png" alt="" />
            <p v-if="oneMeetingData">
              <span v-if="oneMeetingData.statusId == 0">
                {{ $t("text.announcement1") }}{{ oneMeetingData.dayKey
                }}{{ $t("text.announcement2") }}{{ oneMeetingData.title
                }}{{ $t("text.announcement3") }}
              </span>
              <span v-if="oneMeetingData.statusId == 1">
                {{ $t("text.announcement4") }}{{ oneMeetingData.title
                }}{{ $t("text.announcement5") }}
              </span>
            </p>
            <p v-else>{{ $t("text.noMeeting") }}</p>
          </div>
        </div>
        <div
          class="header-right"
          v-if="userData.userType == 2"
          @click="handleApplyFor"
        >
          <img src="@/assets/applyfor.png" alt="" />
          <p>{{ $t("btn.apply") }}</p>
        </div>
      </div>
      <div class="list">
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane :label="$t('text.myCourses')" :name="'1'">
            <el-table :data="orderList" style="width: 100%" height="350">
              <el-table-column prop="title" :label="$t('text.CourseName')">
              </el-table-column>
              <el-table-column
                prop="dayKey"
                :label="$t('text.beginningAndEndingTime')"
                width="160px"
              >
              </el-table-column>
              <el-table-column
                prop="useTotalMinutesResult"
                :label="$t('text.Duration')"
              >
                <template slot-scope="scope">
                  {{ scope.row.useTotalMinutesResult || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="businessDatasCount"
                :label="$t('text.coursePeople')"
              >
              </el-table-column>
              <el-table-column
                prop="zjBusinessDatasCount"
                :label="$t('text.assistantPeople')"
              >
              </el-table-column>
              <el-table-column prop="userName" :label="$t('text.initiator')">
                <template slot-scope="scope">
                  {{ scope.row.userName || "--" }}（{{
                    scope.row.userCode || "--"
                  }}）
                </template>
              </el-table-column>
              <el-table-column
                prop="enteringTheMeetingStatus"
                :label="$t('text.Status')"
              >
                <template slot-scope="scope">
                  <span
                    :class="
                      scope.row.enteringTheMeetingStatus == 1
                        ? 'textColor3'
                        : 'textColor2'
                    "
                  >
                    {{
                      scope.row.enteringTheMeetingStatus == 1
                        ? $t("text.canEnter")
                        : scope.row.enteringTheMeetingStatus == 0
                        ? $t("text.cannotEnter")
                        : ""
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="verifyStatusId"
                :label="$t('text.courseStatus')"
                width="80"
              >
                <template slot-scope="scope">
                  <span
                    :class="
                      scope.row.verifyStatusId == 1
                        ? 'textColor1'
                        : scope.row.verifyStatusId == 3
                        ? 'textColor2'
                        : scope.row.statusId == 0
                        ? ''
                        : scope.row.statusId == 1
                        ? 'textColor3'
                        : scope.row.statusId == 2
                        ? ''
                        : scope.row.statusId == 3
                        ? ''
                        : ''
                    "
                  >
                    {{
                      scope.row.verifyStatusId == 1
                        ? $t("text.waitApproval")
                        : scope.row.verifyStatusId == 3
                        ? $t("text.reject")
                        : scope.row.statusId == 0
                        ? $t("text.notStart")
                        : scope.row.statusId == 1
                        ? $t("text.inProgress")
                        : scope.row.statusId == 2
                        ? $t("text.end")
                        : scope.row.statusId == 3
                        ? $t("text.Canceled")
                        : $t("text.Invaild")
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="id" :label="$t('text.recordingFile')">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleCheck(scope.row.id)"
                    >查看</el-button
                  >
                </template>
              </el-table-column>
              <span slot="empty">
                <el-empty :description="$t('text.noMeetingRecord')"></el-empty>
              </span>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('text.liveList')" :name="'2'">
            <el-table :data="liveList" style="width: 100%" height="350">
              <el-table-column prop="name" :label="$t('text.liveName')">
              </el-table-column>
              <el-table-column
                prop="userName"
                :label="$t('text.keynoteSpeaker')"
              >
              </el-table-column>
              <el-table-column
                prop="status"
                :label="$t('text.liveStatus')"
                width="80"
              >
                <template slot-scope="scope">
                  <span
                    :class="
                      scope.row.status == 1
                        ? 'textColor1'
                        : scope.row.status == 2
                        ? 'textColor2'
                        : ''
                    "
                  >
                    {{
                      scope.row.status == 1
                        ? "直播中"
                        : scope.row.status == 2
                        ? "已结束"
                        : "未开始"
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="preBroadcastTime"
                :label="$t('text.warningTime')"
              >
              </el-table-column>
              <el-table-column prop="startTime" :label="$t('text.startTime')">
                <template slot-scope="scope">
                  {{
                    scope.row.startTime
                      ? moment(scope.row.startTime).format("YYYY-MM-DD HH:mm")
                      : "暂无"
                  }}
                </template>
              </el-table-column>

              <el-table-column prop="id" :label="$t('text.operate')">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    v-if="scope.row.status == 1"
                    @click="handleLiveCheck(1, scope.row)"
                    >{{ $t('text.viewLiveStream') }}</el-button
                  >
                  <el-button
                    type="text"
                    v-else-if="scope.row.status == 2"
                    @click="handleLiveCheck(2, scope.row)"
                    >{{ $t('text.viewPlayback') }}</el-button
                  >
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <span slot="empty">
                <el-empty :description="$t('text.noLiveRecords')"></el-empty>
              </span>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <!-- 分页 -->
        <!-- @size-change="handleSizeChange" -->
        <div class="page-wrap">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="
              Math.floor(queryParams.SkipCount / queryParams.MaxResultCount) + 1
            "
            :page-size="queryParams.MaxResultCount"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="home-right">
      <div class="user-data">
        <div class="user-data-img">
          <img
            v-if="userData && userData.avatarUrl"
            :src="userData.avatarUrl"
            fit="cover"
            alt=""
          />
          <img v-else src="@/assets/user.png" alt="" />
          <!-- <el-upload
            class="upload"
            :action=""
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
          /> -->
          <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-success="handleSuccess"
            :on-error="handleError"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :limit="1"
            accept="image/*"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <p>{{ userData.name }}</p>
        <p>{{ userData.userCode }}</p>
      </div>
      <el-tabs
        style="padding: 0 8px"
        v-model="activeDay"
        @tab-click="handleClick"
        v-if="dayList && dayList.length > 0"
      >
        <el-tab-pane
          v-for="day in dayList"
          :key="day"
          :label="day"
          :name="day"
        ></el-tab-pane>
      </el-tabs>
      <div class="steps-wrap">
        <el-steps
          direction="vertical"
          :active="1"
          finish-status="wait"
          status="wait"
        >
          <el-step
            v-for="item in activeList"
            :key="item.id"
            :title="item.dayKey.split(' ')[1]"
          >
            <template #icon>
              <img src="@/assets/step-icon.png" alt="" />
            </template>
            <template #description>
              <div class="step-con" @click="handleDetail(item)">
                <p>{{ item.title }}</p>
                <p>
                  <span>发起人:{{ item.userName }}</span>
                  <span
                    :class="
                      item.verifyStatusId == 1
                        ? 'textColor1'
                        : item.verifyStatusId == 3
                        ? 'textColor2'
                        : item.statusId == 0
                        ? ''
                        : item.statusId == 1
                        ? 'textColor3'
                        : item.statusId == 2
                        ? ''
                        : item.statusId == 3
                        ? ''
                        : ''
                    "
                  >
                    {{
                      item.verifyStatusId == 1
                        ? "待审批"
                        : item.verifyStatusId == 3
                        ? "审核不通过"
                        : item.statusId == 0
                        ? "未开始"
                        : item.statusId == 1
                        ? "进行中"
                        : item.statusId == 2
                        ? "已结束"
                        : item.statusId == 3
                        ? "已取消"
                        : "失效"
                    }}
                  </span>
                </p>
              </div>
            </template>
          </el-step>
        </el-steps>
      </div>
      <el-button
        type="primary"
        :loading="refreshLogin"
        class="refreshBtn"
        icon="el-icon-refresh-left"
        @click="handleRefresh"
        >{{ $t("text.refresh") }}</el-button
      >
      <el-select
        v-model="selectLang"
        size="mini"
        class="lang"
        @change="handleLangChange"
      >
        <el-option
          v-for="item in langoptions"
          :key="item.value"
          :label="item.text"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <img
        src="@/assets/go-out.png"
        alt=""
        class="go-out"
        @click="handelGoOut"
      />
    </div>
    <apply-for ref="applyFor" @applyForOk="applyForOk"></apply-for>
    <!-- 录制文件 -->
    <el-dialog
      :title="$t('text.recordingFile')"
      :visible.sync="recordingVisible"
      width="40%"
      :before-close="handleRecordingClose"
    >
      <div class="file-list">
        <ul v-if="recordingFile && recordingFile.length > 0">
          <li v-for="i in recordingFile" :key="i.id">
            <div class="file-list__title">
              <!-- <img style="height: 48px" src="@/assets/file.png" alt="" /> -->
              <p class="text">
                {{ i.name }}
              </p>
            </div>
            <div style="color: #416fff; font-size: 12px; cursor: pointer">
              <el-button
                type="text"
                @click="handleRecordingDownLoad(i)"
                v-if="i.downLoadUrlFile"
                >{{ $t("text.download") }}</el-button
              >
              <span v-else>{{ i.statusName }}</span>
            </div>
          </li>
        </ul>
        <el-empty :description="$t('text.noFile')" v-else></el-empty>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="handleRecordingClose">{{
          $t("text.cancel")
        }}</el-button> -->
        <el-button type="primary" @click="handleRecordingClose">{{
          $t("text.close")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 直播回放列表 -->
    <live-playback ref="livePlayback"></live-playback>
  </div>
</template>
<script>
// import util from "../utils/util.js";
import {
  getUserInformation,
  getHomeMeetingReserve,
  getMyAttendanceRecords,
  getLiveList,
  uploadFile,
  userInformation,
} from "../api/home";
import { userMeetingRecordingFiles } from "../api/meeting";
import { logout } from "../api/login";
import applyFor from "./applyFor.vue";
import moment from "moment";
import { Toast } from "vant";
import LivePlayback from "./modal/LivePlayback.vue";
export default {
  components: {
    applyFor,
    LivePlayback,
    LivePlayback,
  },
  data() {
    return {
      uploadUrl: window._config.apiBaseUrl + "/api/app/materials/upload-file",
      // process.env.VUE_APP_BASE_API + "/api/app/materials/upload-file",
      // uploadUrl: "http://192.168.50.250:8022/api/app/materials/upload-file",
      userData: {},
      isLoading: false,
      oneMeetingData: null,
      homeList: [],
      dayList: [],
      activeDay: "",
      activeList: [],
      queryParams: {
        Key: "",
        SkipCount: 0,
        MaxResultCount: 4,
      },
      orderList: [],
      activeName: "1",
      liveList: [],
      total: 0,
      langoptions: [
        { text: "简体中文", value: "zh-CN" },
        { text: "English", value: "en-US" },
      ],
      selectLang: "zh-CN",
      refreshLogin: false,
      recordingVisible: false,
      recordingFile: [],
    };
  },
  created() {
    this.getUserInformationFun();
    this.getListFun();
    this.getRecordsFun();
    let lang = localStorage.getItem("app_language")
      ? localStorage.getItem("app_language")
      : "zh-CN";
    this.selectLang = lang;
    this.$store.dispatch("setLang", lang);
  },
  methods: {
    moment,
    handleRefresh() {
      this.refreshLogin = true;
      location.reload();
    },
    getUserInformationFun() {
      getUserInformation().then((res) => {
        this.userData = res;
        this.$store.commit("SET_USER_DATA", res);
      });
    },
    getListFun() {
      getHomeMeetingReserve().then((res) => {
        this.dayList = [];
        let new_homeList = [];
        this.homeList = res.items;
        res.items.forEach((item) => {
          this.dayList.push(moment(item.day).format("YYYY-MM-DD"));
          new_homeList = new_homeList.concat(item.items);
        });
        this.activeDay =
          this.dayList && this.dayList.length > 0 ? this.dayList[0] : "";
        this.activeList =
          this.homeList && this.homeList.length > 0
            ? this.homeList[0].items
            : [];
        this.oneMeetingData = new_homeList.find(
          (item) =>
            item.statusId === 1 ||
            (item.verifyStatusId == 2 && item.statusId === 0)
        );
      });
    },
    getRecordsFun() {
      getMyAttendanceRecords(this.queryParams).then((res) => {
        this.orderList = res.items;
        this.total = res.totalCount;
        // this.orderTotal=30;
      });
    },
    getLiveListFun() {
      getLiveList(this.queryParams).then((res) => {
        this.liveList = res.items;
        this.total = res.totalCount;
      });
    },
    handleCurrentChange(val) {
      this.queryParams.SkipCount = (val - 1) * this.queryParams.MaxResultCount;
      this.getRecordsFun();
    },
    handleClick() {
      this.homeList.forEach((item) => {
        let new_day = moment(item.day).format("YYYY-MM-DD");
        if (new_day == this.activeDay) {
          this.activeList = item.items;
        }
      });
    },
    // onRefresh() {
    //   setTimeout(() => {
    //     Toast("刷新成功");
    //     this.isLoading = false;
    //     this.getListFun();
    //   }, 1000);
    // },
    handleApplyFor() {
      this.$refs.applyFor.onShow();
    },
    // handleRecord() {
    //   this.$router.push({ name: "record" });
    // },
    handleDetail(val) {
      // 审批状态 1 待审批 2：审核通过、3：审核不通过;
      // 状态，0:未开始，1:进行中，2:已结束，3:已取消、4:失效 失效=要求签到的情况下，未进行签到和签出，导致预约失效
      if (val.verifyStatusId == 1) {
        this.$refs.applyFor.onShow({ id: val.id });
        // this.$router.push({ name: "applyFor", query: { id: val.id } });
      } else if (val.verifyStatusId == 3) {
        this.$refs.applyFor.onShow({ id: val.id });

        // this.$router.push({ name: "applyFor", query: { id: val.id } });
      } else {
        if (
          val.enteringTheMeetingStatus == 1 &&
          (val.statusId == 1 || val.statusId == 0)
        ) {
          this.$router.push({
            name: "meeting",
            query: { userId: this.userData.userCode, id: val.id },
          });
        } else {
          this.$notify({
            title: "消息",
            message: "课程尚未开始，请等待",
            type: "info",
          });
        }
      }
    },
    // sorry() {
    //   // Toast('暂无后续逻辑~');
    // },
    handelGoOut() {
      this.$confirm("确定退出？")
        .then((_) => {
          logout().then((res) => {
            localStorage.removeItem("storage_key");
            localStorage.removeItem("userData");
            // Toast.success("退出成功");
            // if (res) {
            //   window.location.href = res;
            // } else {
            this.$router.push({
              path: "/login",
            });
            // }
          });
        })
        .catch((_) => {});
    },
    applyForOk(data) {
      // postMeetingReserve(data).then((res) => {
      this.getListFun();
      this.getRecordsFun();
      // });
    },
    handleTabClick(tab, event) {
      if (tab.name == 2) {
        // 直播记录
        this.queryParams.SkipCount = 0;
        this.queryParams.MaxResultCount = 4;
        this.getLiveListFun();
      } else {
        this.queryParams.SkipCount = 0;
        this.queryParams.MaxResultCount = 4;
        this.getRecordsFun();
      }
    },

    handleLangChange(lang) {
      this.$store.dispatch("setLang", lang);
    },
    beforeAvatarUpload(file) {
      const isImage = file.type.startsWith("image/");
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImage) {
        this.$message.error("上传文件只能是图片格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return false;
      }
      return true;
    },
    // 文件上传成功时的钩子，参数为响应数据、文件、文件列表
    handleSuccess(response, file, fileList) {
      let fileId = response.id;
      userInformation({ id: fileId }).then((res) => {
        Toast("头像修改成功");
        this.getUserInformationFun();
      });
    },
    // 文件上传失败时的钩子，参数为错误信息、文件、文件列表
    handleError(err, file, fileList) {
      Toast("上传失败");
    },
    // 查看文件
    handleCheck(meetingId) {
      this.recordingVisible = true;
      userMeetingRecordingFiles({ MeetngId: meetingId }).then((res) => {
        this.recordingFile = res;
      });
    },
    handleRecordingClose() {
      this.recordingVisible = false;
      this.recordingFile = [];
    },
    handleRecordingDownLoad(file) {
      // 创建一个a元素
      const a = document.createElement("a");
      // 设置href属性为提供的URL
      a.href = file.downLoadUrlFile;
      // 设置download属性为提供的文件名
      a.download = file.name;
      // 将a元素添加到文档中，并触发点击事件
      document.body.appendChild(a);
      a.click();
      // 移除a元素
      document.body.removeChild(a);
    },
    // 直播-操作
    handleLiveCheck(type, row) {
      if (type == 1) {
        // 直播中
        let token = localStorage.getItem("storage_key");
        let path = "/live?id=" + row.id + "&tk=" + encodeURIComponent(token);
        // let path = 'http://localhost:8080/live?id=' + row.id + '&tk='+encodeURIComponent(token)
        window.open(path, "_blank");
      }
      if (type == 2) {
        // 查看回放
        this.$refs.livePlayback.onShow(row.id);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./css/home.less";
</style>
