<template>
  <el-dialog
    :title="$t('text.selectAttendPeople')"
    :center="true"
    :visible.sync="dialogVisible"
    class="personnel"
    :close-on-click-modal="false"
  >
    <div>
      <p>
        {{ $t("text.attendTeacher") }}：<span
          v-for="item in teacherList"
          :key="item.personNo"
          >{{ item.userName }}、</span
        >
      </p>
      <p>
        {{ $t("text.attendStudent") }}：<span
          v-for="item in studentList"
          :key="item.personNo"
          >{{ item.userName }}、</span
        >
      </p>
    </div>
    <div class="demo-input-suffix">
      <el-input
        placeholder="请输入学号或姓名"
        v-model="queryParam.Key"
        clearable
        @change="handleSearch"
        
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="handleSearch"
        ></i>
      </el-input>
    </div>
    <div class="wrap">
      <div class="tree-wrap">
        <p :class="!type ? 'active' : ''" @click="handleTeacher">
          <!-- <i
            class="el-icon-caret-right"
            style="padding: 0.04848rem; color: #606266"
          ></i> -->
          <span style="margin-left: 25px">教职工</span>
        </p>
        <el-tree
          :data="sidelist"
          :props="defaultProps"
          :current-node-key="selectedKeys"
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
      <div class="personnel-list">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :row-key="(row) => row.personNo"
          @selectionChange="handleSelectionChange"
          @select="handleSelectRow"
          @select-all="handleSelectAllRow"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column :label="$t('text.studentNumber')" width="120">
            <template slot-scope="scope">{{ scope.row.personNo }}</template>
          </el-table-column>
          <el-table-column prop="name" :label="$t('text.name')" width="120">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">{{ $t("text.cancel") }}</el-button>
      <el-button type="primary" @click="submitPersonnel">{{
        $t("text.confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog, Toast } from "vant";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { getOrgTree, getStudent, getTeacher } from "../../api/org.js";
const generateList = (data) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const key = node.key;
    dataList.push({ key, title: node.title });
    if (node.children) {
      generateList(node.children);
    }
  }
};
const queryParam = {
  Key: "",
  SkipCount: 0,
  MaxResultCount: 999,
  Name: "",
  ClassroomId: null,
  DepartmentParentId: null,
};
export default {
  components: {},
  props: ["personnelArr"],
  data() {
    return {
      dialogVisible: false,
      defaultProps: {
        children: "children",
        label: "label",
      },
      sidelist: [],
      expandedKeys: [],
      selectedKeys: "",
      type: false,
      queryParam: this.clone(queryParam),
      tableData: [],
      total: 100,
      multipleSelection: [],
      studentList: [],
      teacherList: [],
    };
  },
  watch: {
    studentList: {
      handler(newStudentList) {
        this.updateMultipleSelection();
      },
      deep: true,
    },
    teacherList: {
      handler(newTeacherList) {
        this.updateMultipleSelection();
      },
      deep: true,
    },
  },
  mounted() {
    this.getTree();
  },
  methods: {
    moment,
    updateMultipleSelection() {
      this.multipleSelection = [...this.studentList, ...this.teacherList];
    },
    clone(obj) {
      let objClone = JSON.parse(JSON.stringify(obj));
      return objClone;
    },
    showModal() {
      this.dialogVisible = true;
      this.studentList = this.personnelArr.studentList;
      this.teacherList = this.personnelArr.teacherList;
      this.type = false;
      this.getList();
    },
    handleSearch() {
      this.getList();
    },
    getTree() {
      this.sidelist = [];
      // this.queryParam.lastLevel = this.orgDefineLevel
      getOrgTree(this.queryParam).then((res1) => {
        let res = res1[0];
        // if (this.selectedKeys.length == 0) {
        //   this.selectedKeys.push(res.id);
        //   this.selectedTitle = res.name;
        //   this.selectedchildLevel = res.childDefineLevel;
        //   this.selectedlevel = res.orgDefineLevel;
        //   this.$emit(
        //     "selectedData",
        //     res.id,
        //     this.selectedTitle,
        //     this.selectedlevel,
        //     this.selectedchildLevel,
        //     res.parentId
        //   );
        // } else {
        //   this.getObjById(res1, this.selectedKeys[0]);
        // }

        /*// if (this.expandedKeys.length == 0) {
        //   this.expandedKeys.push(res.id)
        // }*/
        var treelist = JSON.parse(
          JSON.stringify(res).replace(/name/g, "label").replace(/id/g, "key")
        );
        this.treeTraversal(treelist);

        this.sidelist.push(treelist);
        // generateList(this.sidelist);
        this.treeList = treelist;
        // this.$emit('treeOk', treelist)
      });
    },
    getObjById(list, selectedKey) {
      if (!list instanceof Array) {
        return null;
      } else {
        for (var i = 0; i < list.length; i++) {
          let item = list[i];
          if (item.id === selectedKey) {
            if (item) {
              this.selectedTitle = item.name;
              this.selectedchildLevel = item.childDefineLevel;
              this.selectedlevel = item.orgDefineLevel;
              this.$emit(
                "selectedData",
                item.id,
                this.selectedTitle,
                this.selectedlevel,
                this.selectedchildLevel,
                item.parentId
              );
            }
          } else {
            if (item.children) {
              let value = this.getObjById(item.children, selectedKey);
              if (value) {
                this.selectedTitle = value.name;
                this.selectedchildLevel = value.childDefineLevel;
                this.selectedlevel = value.orgDefineLevel;
                // return value
                this.$emit(
                  "selectedData",
                  value.id,
                  this.selectedTitle,
                  this.selectedlevel,
                  this.selectedchildLevel,
                  value.parentId
                );
              }
            }
          }
        }
      }
    },
    /**递归遍历 */
    treeTraversal(node) {
      // if (node.status) {
      node.scopedSlots = { title: "custom" };
      // }
      if (node.children) {
        for (var i = 0; i < node.children.length; i++) {
          this.treeTraversal(node.children[i]);
        }
      }
    },
    handleNodeClick(data) {

      this.type = true;
      if (data.orgDefineLevel == 3) {
        this.queryParam.DepartmentParentId = data.key;
        this.queryParam.ClassroomId = null;
      } else if (data.orgDefineLevel == 7) {
        this.queryParam.DepartmentParentId = null;
        this.queryParam.ClassroomId = data.key;
      } else {
        this.queryParam.DepartmentParentId = null;
        this.queryParam.ClassroomId = null;
      }
      this.getList();
    },
    handleTeacher() {
      this.type = false;
      this.selectedKeys = "";
      this.queryParam.ClassroomId = null;
      this.queryParam.DepartmentParentId = null;
      this.getList();
    },
    getList() {
      if (!this.type) {
        getTeacher(this.queryParam).then((res) => {
          this.tableData = res.items;
          this.total = res.totalCount;
          this.initSelectedRows();
        });
      } else {
        getStudent(this.queryParam).then((res) => {
          res.items.forEach((item) => {
            item.personNo = item.personalNo;
            delete item.personalNo;
          });
          this.tableData = res.items;
          this.total = res.totalCount;
          this.initSelectedRows();
        });
      }
    },
    initSelectedRows() {
      this.$nextTick(() => {
        this.tableData.forEach((row1) => {
          this.multipleSelection.forEach((row2) => {
            if (row1.personNo == row2.userCode) {
              this.$refs.multipleTable.toggleRowSelection(row1, true);
            }
          });
        });
      });
    },

    handleSelectionChange(val) {
      // selection: 已选中的行数据
      this.multipleSelection = val;
    },
    handleSelectRow(selection, row) {
      if (selection.includes(row)) {
        // 当前行数据被选中
        if (!this.type) {
          this.teacherList.push({
            userCode: row.personNo,
            userName: row.name,
          });
        } else {
          this.studentList.push({
            userCode: row.personNo,
            userName: row.name,
          });
        }
      } else {
        // 当前行数据被取消选中
        if (!this.type) {
          this.teacherList.map((item, index) => {
            if (item.userCode === row.personNo) {
              this.teacherList.splice(index, 1);
            }
          });
        } else {
          this.studentList.map((item, index) => {
            if (item.userCode === row.personNo) {
              this.studentList.splice(index, 1);
            }
          });
        }
      }
    },
    handleSelectAllRow(selection) {
      // 处理全选的逻辑
      if (selection.length === this.tableData.length) {
        // 全选逻辑
        if (!this.type) {
          let personNo_list = this.teacherList.map((item) => item.userCode);
          selection.forEach((item) => {
            if (!personNo_list.includes(item.personNo)) {
              this.teacherList.push({
                userCode: item.personNo,
                userName: item.name,
              });
            }
          });
        } else {
          let personNo_list = this.studentList.map((item) => item.userCode);
          selection.forEach((item) => {
            if (!personNo_list.includes(item.personNo)) {
              this.studentList.push({
                userCode: item.personNo,
                userName: item.name,
              });
            }
          });
        }
      } else {
        // 非全选逻辑
        if (!this.type) {
          this.teacherList = this.teacherList.filter((item) => {
            let res = true; //是否保存该item
            this.tableData.forEach((val) => {
              if (item.userCode == val.personNo) {
                res = false;
              }
            });
            return res;
          });
        } else {
          this.studentList = this.studentList.filter((item) => {
            let res = true; //是否保存该item
            this.tableData.forEach((val) => {
              if (item.userCode == val.personNo) {
                res = false;
              }
            });
            return res;
          });
        }
      }
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    submitPersonnel() {
      this.dialogVisible = false;

      // if (this.multipleSelection && this.multipleSelection.length > 0) {
      //   this.multipleSelection.forEach((val) => {
      //     personnelArr.push({ personNo: val.personNo, name: val.name });
      //   });
      // }
      this.$emit("personnelOk", {
        studentList: this.studentList,
        teacherList: this.teacherList,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 50vh;
  display: flex;
  p {
    margin: 0;
  }
  /deep/.el-dialog__body {
    padding-top: 0;
    .el-form-item__label {
      padding-bottom: 0;
      font-size: 14px;
    }
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .tree-wrap {
    width: 40%;
    .active {
      background-color: #f5f7fa;
    }
  }
  /deep/.el-table__body-wrapper {
    max-height: 400px;
    overflow-y: scroll;
  }
}
</style>
