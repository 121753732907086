<template>
  <div>
    <el-dialog
      :title="$t('btn.apply')"
      :center="true"
      :visible.sync="dialogFormVisible"
      class="applyFor"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm" label-position="top">
        <el-form-item :label="$t('text.CourseName')" prop="title">
          <el-input v-model="form.title" :disabled="!isEdit"></el-input>
        </el-form-item>
        <el-form-item :label="$t('text.meetingIntroduction')">
          <el-input
            v-model="form.introduce"
            type="textarea"
            :disabled="!isEdit"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('text.attendPeople')">
          <el-button style="margin-right: 20px" @click="handlePersonnel">{{
            $t("text.selectAttendPeople")
          }}</el-button>
          <p v-if="personnelArr.teacherList.length > 0">
            参会教师：
            <span v-for="item in personnelArr.teacherList" :key="item.userCode"
              >{{ item.userName }}、</span
            >
          </p>
          <p v-if="personnelArr.studentList.length > 0">
            参会学生：
            <span v-for="item in personnelArr.studentList" :key="item.userCode"
              >{{ item.userName }}、</span
            >
          </p>
        </el-form-item>
        <el-form-item :label="$t('text.mentor')">
          <el-select
            v-model="form.zjBusinessDatasManager"
            multiple
            placeholder="请选择"
            style="width: 100%"
            v-if="
              personnelArr.studentList.length > 0 ||
              personnelArr.teacherList.length > 0
            "
          >
            <el-option
              v-for="item in personnelArr.teacherList.concat(
                personnelArr.studentList
              )"
              :key="item.userCode"
              :value="item.userCode"
              :label="item.userName"
            >
            </el-option>
          </el-select>
          <p v-else>{{ $t("text.pleaseSelectAttendPeople") }}</p>
        </el-form-item>
        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <el-form-item :label="$t('text.speakMode')">
              <el-select
                v-model="form.speechModeStatus"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in speakModeArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item :label="$t('text.meetingDate')" prop="day">
              <el-date-picker
                v-model="form.day"
                type="date"
                placeholder="选择日期"
                style="width: 100%"
                @change="onDayChange"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item v-if="form.day" :label="$t('text.meetingTime')">
          <el-time-picker
            is-range
            arrow-control
            v-model="form.time"
            @change="onTimeChange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            style="width: 100%"
          >
          </el-time-picker>
          <!-- <div v-else>请先选择会议日期</div> -->
        </el-form-item>
        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <el-form-item :label="$t('text.allowEarlyEntry')">
              <el-radio-group v-model="form.advanceMeetingStatus">
                <el-radio :label="0">{{ $t("text.no") }}</el-radio>
                <el-radio :label="1">{{ $t("text.yes") }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="11" v-if="form.advanceMeetingStatus == 1">
            <el-form-item :label="$t('text.earlyEntryTime')">
              <el-input-number
                v-model="form.advanceMeetingMinutes"
                controls-position="right"
                :min="0"
                :max="999"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item :label="$t('text.allowNonAttend')">
          <el-radio-group v-model="form.allowNonAttendeesStatus">
            <el-radio :label="0">{{ $t("text.no") }}</el-radio>
            <el-radio :label="1">{{ $t("text.yes") }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('text.translateRole')">
          <div
            v-if="
              personnelArr.studentList.length > 0 ||
              personnelArr.teacherList.length > 0
            "
          >
            <el-button style="margin-right: 20px" @click="handleTranslators"
              >{{ $t("text.participantsFirst") }}</el-button
            >
            <p
              v-if="
                form.meetingTranslatorsManager.length > 0 ||
                form.meetingTranslatorsManager.length > 0
              "
            >
              <span v-if="form.meetingTranslatorsManager.length > 0">
                <span
                  v-for="item in form.meetingTranslatorsManager"
                  :key="item.userCode"
                >
                  {{ item.userName }}({{ getNameByType(item.type) }})、</span
                >
              </span>
            </p>
            <p v-else>{{$t('text.noTranslatorsAvailable')}}</p>
          </div>
          <span v-else> {{$t('text.pleaseSelectParticipantsFirst')}} </span>
        </el-form-item>
        <el-form-item :label="$t('text.realTimeTrans')">
          <el-radio-group v-model="form.phoneticTranscriptionStatus">
            <el-radio :label="0">{{ $t("text.no") }}</el-radio>
            <el-radio :label="1">{{ $t("text.yes") }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('text.virtualBackground')">
          <el-radio-group 
          v-model="form.virtualBackgroundStatus"
          :disabled="!backgroundStatus"
          >
            <el-radio :label="0">{{ $t("text.no") }}</el-radio>
            <el-radio :label="1">{{ $t("text.yes") }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.virtualBackgroundStatus == 1">
          <el-card>
            <div slot="header" class="clearfix">
              <span>{{ $t("text.defaultBackground") }}</span>
            </div>
            <el-checkbox-group v-model="selectVirtual">
              <div class="card-wrap">
                <div v-for="val in bgList" :key="val.materialId">
                  <el-image
                    style="width: 100px; height: 100px"
                    fit="cover"
                    :src="val.materialIdUrl"
                    :preview-src-list="[val.materialIdUrl]"
                  >
                  </el-image>
                  <p class="card-p">
                    <el-checkbox :label="val.materialId">{{
                      val.name
                    }}</el-checkbox>
                  </p>
                </div>
              </div>
            </el-checkbox-group>
          </el-card>
          <el-card>
            <div slot="header" class="clearfix">
              <span>{{ $t("text.customBackground") }}</span>
            </div>
            <div>
              <el-upload
                class="upload-demo"
                action=""
                :http-request="bgUploadFile"
                :file-list="bgFileList"
                :on-remove="handleRemoveBg"
                :before-upload="beforeAvatarUpload"
                list-type="picture"
              >
                <el-button size="small" type="primary"
                  >{{$t("text.clickCustomizeBackground")}}</el-button
                >
                <span slot="tip" class="el-upload__tip"
                  >{{$t("text.clickCustomizeBackground")}}</span
                >
              </el-upload>
            </div>
          </el-card>
        </template>
        <el-form-item :label="$t('text.exportMeetingSummary')">
          <el-radio-group v-model="form.meetingMinutesStatus">
            <el-radio :label="0">{{ $t("text.no") }}</el-radio>
            <el-radio :label="1">{{ $t("text.yes") }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('text.meetingShareFile')">
          <el-upload
            class="upload-file"
            action=""
            :http-request="uploadFile"
            :file-list="fileList"
            :on-remove="handleRemove"
          > 
            <el-button  size="small" type="primary">{{
              $t("text.clickUpload")
            }}</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
        <ul class="file-list">
           <li v-for="file in fileList" :key="file.uid">
              <span>{{file.name}}</span>
              <i class="el-icon-close" @click="onCloseFile(file)"></i>
           </li>
        </ul>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClear">{{ $t("text.cancel") }}</el-button>
        <el-button type="primary" @click="submitApplyFor">{{
          $t("text.confirm")
        }}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="$t('text.PleaseEnterBackground')" :visible.sync="visible" width="30%">
      <el-input
        v-model="bgName"
        :placeholder="$t('text.PleasePictureBackgroundName')"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">{{$t('text.cancel')}}</el-button>
        <el-button type="primary" @click="handleBgNameOk">{{$t('text.confirm')}}</el-button>
      </span>
    </el-dialog>
    <select-personnel
      ref="personnel"
      :personnelArr="personnelArr"
      @personnelOk="personnelOk"
    ></select-personnel>
    <select-translators ref="selectTranslators" @ok="selectTranslatorsOk">
    </select-translators>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import moment from "moment";
import { mapActions, mapState } from "vuex";

import {
  meetingVirtualBackgroundCore
} from "../api/meeting";
import {
  uploadFile,
  postMeetingReserve,
  getMeetingReserveDetail,
  putMeetingReserve,
  getLangApi,
  getVirtualBackgroundCommands,
} from "../api/home.js";

import SelectPersonnel from "./modal/SelectPersonnel";
import SelectTranslators from "./modal/SelectTranslators";

import util from "../utils/util.js";
import i18n from "../locales/index.js";

const infoForm = {
  title: "",
  introduce: "",
  beginTime: "",
  endTime: "",
  businessDatasManager: [],
  zjBusinessDatasManager: [],
  materialsDatasManager: [],
  speechModeStatus: 0,
  allowNonAttendeesStatus: 0,
  materialsDatas: [],
  day: "",
  time: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
  advanceMeetingStatus: 0, // 是否允许提前入会 0 不允许 1 允许
  advanceMeetingMinutes: 0, // 提前入会分钟数
  virtualBackgroundStatus: 0, //虚拟背景 0 禁用 1 启用
  phoneticTranscriptionStatus: 0, //是否使用语音转写 0 禁用 1启用
  meetingMinutesStatus: 0, //是否导出会议纪要 0 禁止 1 启用
  meetingTranslatorsManager: [], //翻译人员
  meetingVirtualBackgroundsManager: [], //虚拟背景
};
export default {
  components: { SelectPersonnel, SelectTranslators },
  data() {
    return {
      dialogFormVisible: false,
      formLabelWidth: "120px",
      id: "",
      isEdit: true,
      form: this.clone(infoForm),
      timeType: 1,
      timeShow: false,
      currentDate: new Date(),
      assistantShow: false,
      selectAssistantId: [],
      assistantArr: [],
      speakModeShow: false,
      speakModeArr: [
        { id: 0, name: i18n.tc("text.freeSpeakMode") },
        { id: 1, name: i18n.tc("text.hostSpeakMode") },
        // { id: 2, name: "禁言" },
      ],
      fromPersonnelRoute: false,
      fileList: [],
      personnelArr: {
        studentList: [],
        teacherList: [],
      },
      rules: {
        title: [{ required: true, message: "请输入课程名称", trigger: "blur" }],
        day: [
          {
            type: "date",
            required: true,
            message: "请选择课程日期",
            trigger: "change",
          },
        ],
      },
      selectTranslators: [],
      langList: [],
      bgList: [],
      selectVirtual: [],
      bgFileList: [],
      bgName: "",
      fileToUpload: null,
      visible: false,
      // baseURL: process.env.VUE_APP_BASE_API,
      baseURL: window._config.apiProxyUrl,

      backgroundStatus:false
    };
  },
  mounted() {
    this.getLangFun();
    this.getVirtualBackgroundFun();
    meetingVirtualBackgroundCore().then((res) => {
      this.backgroundStatus = res
    })
  },
  methods: {
    moment,
    clone(obj) {
      let objClone = JSON.parse(JSON.stringify(obj));
      return objClone;
    },
    personnelOk(data) {
      this.personnelArr = data;
    },
    onDayChange(e) {
      let new_day = this.moment(this.form.day).format("YYYY-MM-DD");
      let begin = this.moment(this.form.time[0]).format("HH:mm");
      let end = this.moment(this.form.time[1]).format("HH:mm");
      this.form.beginTime = new_day + " " + begin;
      this.form.endTime = new_day + " " + end;
    },
    onTimeChange(e) {
      let new_day = this.moment(this.form.day).format("YYYY-MM-DD");
      let begin = this.moment(e[0]).format("HH:mm");
      let end = this.moment(e[1]).format("HH:mm");
      this.form.beginTime = new_day + " " + begin;
      this.form.endTime = new_day + " " + end;
    },
    onShow(val) {
      this.dialogFormVisible = true;
      this.personnelArr.studentList = [];
      this.personnelArr.teacherList = [];
      if (val && val.id) {
        this.id = val.id;
        getMeetingReserveDetail({ id: val.id }).then((res) => {
          let new_form = JSON.parse(JSON.stringify(res));
          if (new_form.businessDatas && new_form.businessDatas.length > 0) {
            new_form.businessDatas.forEach((val) => {
              if (new_form.userType == 1) {
                this.personnelArr.studentList.push(val);
              } else if (new_form.userType == 2) {
                this.personnelArr.teacherList.push(val);
              } else {
              }
            });
          }
          new_form.day = moment(new_form.day, "YYYY-MM-DD").toDate();
          new_form.time = [
            this.formatTime(new_form.beginTime),
            this.formatTime(new_form.endTime),
          ];
          if (new_form.materialsDatas && new_form.materialsDatas.length > 0) {
            new_form.materialsDatas.forEach((val) => {
              this.fileList.push({
                uid: val.id,
                icon: util.judgeFileType(val.name),
                name: val.name,
                status: "done",
              });
            });
          }
          this.form = new_form;
        });
      } else {
        this.id = "";
        this.form = this.clone(infoForm);
        this.fileList = [];
        this.personnelArr.studentList = [];
        this.personnelArr.teacherList = [];
      }
    },
    formatTime(dateString) {
      const dateParts = dateString.split(/[- :]/);
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]) - 1; // 月份从0开始，所以需要减1
      const day = parseInt(dateParts[2]);
      const hour = parseInt(dateParts[3]);
      const minute = parseInt(dateParts[4]);
      return new Date(year, month, day, hour, minute);
    },
    handlePersonnel() {
      this.$refs.personnel.showModal();
    },
    //会议助教
    handleAssistant() {
      if (
        this.form.businessDatasManager &&
        this.form.businessDatasManager.length > 0
      ) {
        this.assistantShow = true;
      } else {
        Toast("请先选择参会人员");
      }
    },

    //发言模式
    handleSpeakMode() {
      this.speakModeShow = true;
    },
    onChangeSpeakMode(val) {
      this.speakModeArr.forEach((j) => {
        if (j.id == val) {
          this.form.speakMode = j.name;
        }
      });
    },
    handleRemove(file, fileList) {
      // 处理文件移除
    },
     handleChange(file, fileList) {
      // 最新添加的文件是列表中的最后一个
      const latestFile = fileList[fileList.length - 1];
      if (latestFile) {
        this.uploadFile(latestFile.raw);
      }
    },
    uploadFile(data) {
      const formData = new FormData();
      formData.append("file", data.file);
      uploadFile(formData).then((res) => {
        // 文件上传成功后，将文件信息添加到 fileList
        this.fileList.push({
          uid: res.id,
          icon: util.judgeFileType(data.file.name),
          name: data.file.name,
          status: "done",
        });
      }).catch(error => {
        // this.handleError(error, file);
      });
    },
    onCloseFile(file){
 // 处理文件移除
      const index = this.fileList.indexOf(file);
      if (index !== -1) {
        this.fileList.splice(index, 1); // 从文件列表中删除指定文件
      }
    },
    // 获取语言列表
    getLangFun() {
      getLangApi().then((res) => {
        this.langList = res;
      });
    },
    getNameByType(type) {
      const item = this.langList.find((item) => item.type === type);
      return item ? item.name : "";
    },
    //翻译设置
    handleTranslators() {
      let personnelData = this.personnelArr.teacherList.concat(
        this.personnelArr.studentList
      );
      this.$refs.selectTranslators.modalshow(
        personnelData,
        this.form.meetingTranslatorsManager
      );
    },
    selectTranslatorsOk(data) {
      this.form.meetingTranslatorsManager = data;
    },
    submitApplyFor() {
      // 合并默认背景和自定义背景
      const formatVirtualList = this.bgList.filter((item) =>
        this.selectVirtual.includes(item.materialId)
      );
      const formatBgFileList = this.bgFileList.map((item) => ({
        name: item.name,
        materialId: item.uid,
      }));
      this.form.meetingVirtualBackgroundsManager =
        formatVirtualList.concat(formatBgFileList);

      this.form.businessDatasManager = [];
      this.form.materialsDatasManager = [];
      if (
        this.personnelArr.studentList &&
        this.personnelArr.studentList.length > 0
      ) {
        this.personnelArr.studentList.forEach((element) => {
          this.form.businessDatasManager.push(element.userCode);
        });
      }
      if (
        this.personnelArr.teacherList &&
        this.personnelArr.teacherList.length > 0
      ) {
        this.personnelArr.teacherList.forEach((element) => {
          this.form.businessDatasManager.push(element.userCode);
        });
      }
      this.fileList.forEach((element) => {
        this.form.materialsDatasManager.push(element.uid);
      });
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.id) {
            putMeetingReserve(this.form).then((res) => {
              this.$emit("applyForOk", this.form);
            });
          } else {
            postMeetingReserve(this.form).then((res) => {
              this.$emit("applyForOk", this.form);
            });
          }
          this.handleClear();
        } else {
          return false;
        }
      });
    },
    handleClear() {
      this.form = this.clone(infoForm);
      this.fileList = [];
      this.personnelArr.studentList = [];
      this.personnelArr.teacherList = [];
      this.dialogFormVisible = false;
    },
    // 获取默认背景图
    getVirtualBackgroundFun() {
      getVirtualBackgroundCommands().then((res) => {
        this.bgList = res;
        if (this.bgList && this.bgList.length > 0) {
          res.forEach((val) => {
            this.selectVirtual.push(val.materialId);
          });
        }
      });
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      // return isJPG;
      this.fileToUpload = file; // 保存文件
      this.visible = true; // 显示弹出框
      return false; // 阻止继续上传
    },
    handleBgNameOk() {
      this.visible = false; // 关闭弹出框
      this.bgUploadFile(); // 继续执行上传文件方法
    },
    // 上传自定义背景图
    bgUploadFile(data) {
      const formData = new FormData();
      formData.append("file", this.fileToUpload);
      uploadFile(formData)
        .then((res) => {
          this.bgFileList.push({
            uid: res.id,
            url: this.baseURL + res.url,
            name: this.bgName,
            status: "done",
          });
          this.bgName = "";
          this.fileToUpload = null;
        })
        .catch((error) => {});
    },
    handleRemoveBg(file, fileList) {
      // 处理文件移除
      const index = this.bgFileList.indexOf(file);
      if (index !== -1) {
        this.bgFileList.splice(index, 1); // 从文件列表中删除指定文件
      }
    },
  },
};
</script>

<style lang="less" scoped>
.applyFor {
  min-height: 100vh;
  p {
    margin: 0;
  }
  /deep/.el-dialog__body {
    padding-top: 0;
    .el-form-item__label {
      padding-bottom: 0;
      font-size: 14px;
    }
    .el-form-item {
      margin-bottom: 10px;
    }
  }
}

.card-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  > div {
    box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8,
      1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  }
  .card-p {
    text-align: center;
    padding: 10px 0;
    box-sizing: border-box;
  }
  // .card-checkbox {
  //   text-align: center;
  //   padding-bottom: 10px;
  //   box-sizing: border-box;
  // }
 
}
 /deep/.upload-file {
    .el-upload-list {
      display: none;
    }
  }
.file-list{
  >li{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:2px 10px;
    box-sizing: border-box;
  }
}
</style>
