<template>
  <el-dialog
    :title="$t('text.translateRole')"
    :center="true"
    :visible.sync="dialogVisible"
    class="personnel"
    :close-on-click-modal="false"
  >
    <div class="wrap">
      <div class="personnel-list">
        <el-table
          ref="multipleTable"
          :data="personnelData"
          tooltip-effect="dark"
          style="width: 100%"
          :row-key="(row) => row.personNo"
          @select="handleSelectRow"
          @select-all="handleSelectAllRow"
        >
          <!-- @selectionChange="handleSelectionChange" -->

          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column :label="$t('text.studentNumber')">
            <template slot-scope="scope">{{ scope.row.userCode }}</template>
          </el-table-column>
          <el-table-column prop="name" :label="$t('text.name')" >
            <template slot-scope="scope">{{ scope.row.userName }}</template>
          </el-table-column>
          <el-table-column prop="type" :label="$t('text.translate')">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.type"
                placeholder="请选择"
                style="width: 150px"
              >
                <el-option
                  v-for="val in langList"
                  :key="val.type"
                  :value="val.type"
                  :label="val.name"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">{{ $t("text.cancel") }}</el-button>
      <el-button type="primary" @click="submitPersonnel">{{
        $t("text.confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog, Toast } from "vant";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { getLangApi } from "../../api/home.js";

export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      personnelData: [],
      selectedRows: [],
      langList:[]
    };
  },
  methods: {
    moment,
    updateMultipleSelection() {
      this.multipleSelection = [...this.studentList, ...this.teacherList];
    },
    clone(obj) {
      let objClone = JSON.parse(JSON.stringify(obj));
      return objClone;
    },
    getLangFun() {
      return getLangApi().then((res) => {
        this.langList = res;
      });
    },
    async modalshow(data, selectData) {
      this.dialogVisible = true;
      let jsonData = JSON.parse(JSON.stringify(data));
      let jsonSelectData = JSON.parse(JSON.stringify(selectData));
      // this.selectedRowKeys = jsonSelectData.map((item) => item.userCode);
      this.selectedRows = jsonSelectData;
      await this.getLangFun();
      if (jsonData && jsonData.length > 0) {
        jsonData.forEach((item) => {
          let match = jsonSelectData.find(
            (bItem) => bItem.userCode === item.userCode
          );
          if (match) {
            item.type = match.type;
          } else {
            if (this.langList && this.langList.length > 0) {
              item.type = this.langList[0].type;
            } else {
              item.type = "";
            }
          }
        });
      }
      this.personnelData = jsonData;
      this.initSelectedRows()
      this.dialogVisible = true;
    },
     initSelectedRows() {
      this.$nextTick(() => {
        this.personnelData.forEach((row1) => {
          this.selectedRows.forEach((row2) => {
            if (row1.userCode == row2.userCode) {
              this.$refs.multipleTable.toggleRowSelection(row1, true);
            }
          });
        });
      });
    },

    handleSelectRow(selection, row) {
      this.selectedRows = selection;
    },
    handleSelectAllRow(selection) {
      if (selection.length == 0) {
        this.selectedRows = [];
      } else {
        this.selectedRows = this.personnelData;
      }
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    submitPersonnel() {
      this.dialogVisible = false;
      this.$emit("ok", this.selectedRows);
      this.selectedRows = [];
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 50vh;
  display: flex;
  p {
    margin: 0;
  }
  /deep/.el-dialog__body {
    padding-top: 0;
    .el-form-item__label {
      padding-bottom: 0;
      font-size: 14px;
    }
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .tree-wrap {
    width: 40%;
    .active {
      background-color: #f5f7fa;
    }
  }
  /deep/.el-table__body-wrapper {
    max-height: 400px;
    overflow-y: scroll;
  }
  .personnel-list{
    width: 100%;
  }

}
</style>
