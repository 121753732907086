import request from '../utils/request'

/**
 * @param parameter
 * @returns {*}
 */

export function login(parameter) {
  return request({
      url:  '/app/meeting-phone/login',
      method: 'post',
      data: parameter
  })
}

export function logout(parameter) {
  return request({
      url: '/SSO/logout',
      method: 'post',
      params: parameter
  })
}

// export function importfile(parameter) {
//   return request({
//     url: '/app/educationResources/importCollage',
//     method: 'post',
//     data: parameter
//   })
// }